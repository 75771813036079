import React from 'react';

function Footer(){
  var ano = new Date().getFullYear(); 

    return <section id="footer">    
      <div>
            <ul className="list-unstyled list-inline social text-center">
                <li className="list-inline-item"><a href="https://www.facebook.com/SiscomsoftSistemas"><i className="fa fa-facebook fa-2x"></i></a></li>
                
                <li className="list-inline-item"><a href="mailto:siscomsoft@siscomsoft.com.br"><i className="fa fa-envelope fa-2x"></i></a></li>
            </ul>
	    </div>
      <span>Telefone (62)3954-5085  </span> 
        <a href="mailto:suporte@siscomsoft.com.br">suporte@siscomsoft.com.br</a>
      
      <p>Desenvolvido por Siscomsoft - {ano}</p>

    </section>;
  }

  export default Footer;
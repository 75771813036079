import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../services/api';
import Navbar from '../Components/Navbar/navbar';
import Menu3 from '../Components/Menu/menu3';
import './usuario.css';
import SweetAlert from 'react-bootstrap-sweetalert';

const $ = require('jquery');
$.dataTable = require('datatables.net');

function UserTable() {
    const [users, setUser] = useState([]);
    const [excluido, setExcluido] = useState('');

    const [confirmacao, setConfirmacao] = useState(false);
    const [confirmacaoId, setConfirmacaoId] = useState('');

    useEffect(() => {
        let listaUser = [];
        api.get("/usuarios").then(async function (resultado) {
            await resultado.data.data.forEach(function (doc) {
                //console.log(doc.idUsuario);
                listaUser.push({
                    id: doc.idUsuario,
                    nomeUsuario: doc.nomeUsuario,
                    login: doc.login,
                    administrador: doc.administrador
                });
            })
            setUser(resultado.data.data);
            $(document).ready(function () {
                <font></font>
                $('#usuario').DataTable(
                    {   retrieve: true,
                        paging: false,
                        "language": {
                            "lengthMenu": "Mostrar _MENU_ registros por página",
                            "zeroRecords": "Nada encontrado - desculpe",
                            "info": "Mostrando página _PAGE_ de _PAGES_",
                            "infoEmpty": "Nenhum registro disponível",
                            "infoFiltered": "(filtrado from _MAX_ total records)",
                            "search": "Buscar:",
                            "paginate": {
                                "first": "Primeiro",
                                "last": "Ultimo",
                                "next": "Próximo ",
                                "previous": "Anterior"
                            },
                        }
                    }
                ); <font></font>
            }); <font></font>

        }).catch((err) => {
            console.error("ops! ocorreu um erro" + err);
        });
    }, [excluido]);
    function deleteUser(id) {
        //alert('Exluir usuário ' + id);
        api.delete(`/usuarios/${id}`).then(res => {
            setExcluido(id);
            setConfirmacao(false);
        })
    }
    function confirmDeleteUser(id) {
        setConfirmacaoId(id);
        setConfirmacao(true);
    }

    //console.log(users);
    if (!users) return null;
    return (
        <div>
            <Navbar />
            <div className="offset-lg-1 col-lg-10 titulo" >
                <br></br>
                <h3>Lista de Usuários({users.length})</h3>
                <br></br>
                <div className="row">
                    <div className="col-sm-12">
                        <Link to="/app/novaconta" className="btn btn-primary btn-acao" ><i className="fas fa-plus"></i> Usuário</Link>
                    </div>
                </div>
                <br></br>
                <table id="usuario" className="display" width="90%" >
                    <thead>
                        <tr className="table-secondary">
                            <th scope="col" >Código  </th>
                            <th scope="col" >Nome  </th>
                            <th scope="col" >Usuário  </th>
                            <th scope="col" >Administrador </th>
                            <th scope="col" > </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            users.map((user) => {
                                return <tr key={user.idUsuario}>
                                    <td >{user.idUsuario}</td>
                                    <td>{user.nomeUsuario}</td>
                                    <td>{user.login}</td>
                                    <td>
                                        <input disabled={true} className="custom-control-input" type="checkbox" checked={user.administrador} />
                                    </td>
                                    <td>
                                        <Link to={'/app/editarusuario/' + user.idUsuario}><i className="fas fa-edit icone-acao"></i></Link>
                                        <Link to='#' onClick={() => confirmDeleteUser(user.idUsuario)}><i className="far fa-trash-alt icone-acao red"></i></Link>
                                    </td>

                                </tr>
                            })
                        }
                    </tbody>
                </table>
                {
                    confirmacao ? <SweetAlert
                        warning
                        showCancel
                        showCloseButton
                        confirmBtnText="Sim"
                        confirmBtnBsStyle="danger"
                        cancelBtnText="Não"
                        cancelBtnBsStyle="light"
                        title="Exclusão"
                        onConfirm={() => deleteUser(confirmacaoId)}
                        onCancel={() => setConfirmacao(false)}
                        reverseButtons={true}
                    >
                        Deseja excluir o usuário selecionado?
                    </SweetAlert> : null}
            </div>
            <Menu3 />
        </div>
    )
}
export default UserTable;





import Arquivos from '../Downloads/arquivos'; 
import './home.css';



function Homeb() {

  return <div>
    <Arquivos />
  </div>
}

export default Homeb;
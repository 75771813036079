import React from 'react';

function Downloads() {
    return <section id="download">
        <div className="container">

            <div className="row text-center">
                <div >
                    <h1>Downloads e utilitários  </h1>
                    <p>Programas uteis para utilização dos Sistemas Siscomsoft</p>
                </div>
            </div>

            <div className="row text-center">
                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-header"><h1>Acesso remoto</h1></div>
                        <div className="card-body">
                            <p>O Acesso Remoto é forma de gerenciamento remoto, ou seja, possibilita controlar
                                um computador por meio de outra máquina. Com esta ferramenta conseguimos total suporte ao cliente,
                                com a vantagem de ganho de tempo e agilidade no atendimento, permite a transferência de arquivos,
                                orientações e treinamentos on line .</p>
                            <a className="btn btn-lg btn-outline-primary" rel="noreferrer" aria-current="page" href="http://www.siscomsoft.com.br/downloads/Remoto.exe" target="_blank">Download</a>

                        </div>
                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-header">
                            <h1>Banco de Dados</h1>
                        </div>
                        <div className="card-body">

                            <p>O banco de dados FireBird foi desenvolvido para ser uma ferramenta independente de plataformas e de sistemas operacionais.
                                Este banco de dados, dispensa maiores estruturas dentro da empresa, ( DBA / Preparação ), onde basta instalar o software e usa-lo,
                                sem a interferência frequente de profissionais, especializados na manutenção na base de dados em produção. Acompanhando
                                isso tudo, ele ainda dispensa o uso de super-servidores, usando pouco espaço em disco para sua instalação e utilizando
                                pouca memória em situações normais de uso. Por isso a plataforma necessária para a sua instalação e utilização pode ser
                                reduzida diminuindo consideravelmente os custos do projeto.</p>

                            <a className="btn btn-lg btn-outline-primary" rel="noreferrer" aria-current="page" href="https://www.firebirdsql.org/en/server-packages/" target="_blank">Download</a>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-header">
                            <h1>Instalador e Atualizações</h1>
                        </div>
                        <div className="card-body">
                            
                            <p>Instalador Servidor,Instalador Cliente, Atualizador Comercial, Atualizador Geral,  Sistema Backup</p>
                           
                            <a className="btn btn-lg btn-outline-primary" href="/app">Acessar </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}

export default Downloads;
import React from 'react';

function Bannerrotativo() {
    return <section id="bannerrotativo">
        <div className="container">

            <div id="carouselExampleInterval" className="carousel carousel-dark  slide" data-bs-ride="carousel">
                <div className="carousel-inner">

                    <div className="carousel-item active " data-bs-interval="5000" >
                        <h2>Nossos Sistemas completos e fáceis de usar!</h2>
                        <img alt="img" src="Images/backgroud1.jpg" />
                        <br/><em>Conheça nossas soluções</em>
                    </div>

                    <div className="carousel-item" data-bs-interval="5000">
                        <h2>TEF dedicado, integração com Ifood, Clube de promoções</h2>
                        <img alt="img" src="Images/backgroud2.jpg" />
                        <br/><em>Software Express-Ifood Api - PayGo - Scanntech</em>
                    </div>

                    <div className="carousel-item " data-bs-interval="5000">
                        <h2>Vendas mobile, Força de venda, Comanda eletrônica</h2>
                        <img alt="img" src="Images/backgroud3.jpg" />
                        <br/><em>Sistemas para dispositivos moveis</em>
                    </div>
                    <div className="carousel-item " data-bs-interval="5000">
                        <h2>Gestão dos documentos eletrônicos, XML NFe, XML NFCe, Sped Fiscal, Sped Contribuições</h2>
                        <img alt="img" src="Images/backgroud4.jpg"  />
                        <br/><em>Arquivos para gestão contábil</em>
                    </div>

                   


                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>

        </div>
    </section>;
}


export default Bannerrotativo;
import React from 'react';
import Navbar from '../Components/Navbar/navbar';
import Menu3 from '../Components/Menu/menu3';
import './arquivos.css';

function Arquivos() {
  return <section id="download">
    <Navbar />
    <div className="container titulo">

      <div className="row text-center">
        <div >
          <h1>Downloads e utilitários  </h1>
          <p>Programas uteis para utilização dos Sistemas Siscomsoft</p>
        </div>
      </div>

      <div className="row text-center">
        <div className="col-lg-4">
          <div className="card">
            <div className="card-header"><h1>Instalador Servidor</h1></div>
            <div className="card-body">
              <p>Instalador do sistema Siscomsoft versão Servidor, utilizada para instalar o sistema somente no servidor da empresa.</p>
              <a className="btn btn-lg btn-outline-primary" rel="noreferrer" aria-current="page" href="http://www.siscomsoft.com.br/downloads/setupSR.exe" target="_blank">Download</a>

            </div>
          </div>
        </div>

        <div className="col-lg-4">
          <div className="card">
            <div className="card-header">
              <h1>Instalador Cliente</h1>
            </div>
            <div className="card-body">

              <p>Instalador do sistema Siscomsoft versão cliente, utilizada para instalar o sistema em computadores que não seja o servidor.</p>

              <a className="btn btn-lg btn-outline-primary" rel="noreferrer" aria-current="page" href="http://www.siscomsoft.com.br/downloads/setupCL.exe" target="_blank">Download</a>
            </div>
          </div>
        </div>

        <div className="col-lg-4">
          <div className="card">
            <div className="card-header">
              <h1>Atualizações</h1>
            </div>
            <div className="card-body">

              <p>Este software oferece aos clientes todas as atualizações disponiveis até o momento para melhor aproveitamento do sistema.</p>

              <a className="btn btn-lg btn-outline-primary" rel="noreferrer" aria-current="page" href="http://www.siscomsoft.com.br/downloads/Atualizador_Co.exe" target="_blank">Download</a>
            </div>
          </div>
        </div>
      </div>

      <div className="row text-center">
        <div className="col-lg-4">
          <div className="card">
            <div className="card-header"><h1>Acesso remoto</h1></div>
            <div className="card-body">
              <p>O Acesso Remoto é forma de gerenciamento remoto, ou seja, possibilita controlar
                um computador por meio de outra máquina. Com esta ferramenta conseguimos total suporte ao cliente,
                com a vantagem de ganho de tempo e agilidade no atendimento, permite a transferência de arquivos,
                orientações e treinamentos on-line .</p>
              <a className="btn btn-lg btn-outline-primary" rel="noreferrer" aria-current="page" href="http://www.siscomsoft.com.br/downloads/Remoto.exe" target="_blank">Download</a>

            </div>
          </div>
        </div>

        <div className="col-lg-4">
          <div className="card">
            <div className="card-header">
              <h1>Banco de Dados</h1>
            </div>
            <div className="card-body">

              <p>O banco de dados FireBird foi desenvolvido para ser uma ferramenta independente de plataformas e de sistemas operacionais.
                Este banco de dados, dispensa maiores estruturas dentro da empresa, ( DBA / Preparação ), onde basta instalar o software e usar-lo,
                sem a interferência frequente de profissionais, especializados na manutenção na base de dados em produção. Acompanhando
                isso tudo, ele ainda dispensa o uso de super-servidores, usando pouco espaço em disco para sua instalação e utilizando
                pouca memória em situações normais de uso. Por isso a plataforma necessária para a sua instalação e utilização pode ser
                reduzida diminuindo consideravelmente os custos do projeto.</p>

              <a className="btn btn-lg btn-outline-primary" rel="noreferrer" aria-current="page" href="https://www.firebirdsql.org/en/server-packages/" target="_blank">Download</a>
            </div>
          </div>
        </div>

        <div className="col-lg-4">
          <div className="card">
            <div className="card-header">
              <h1>Siscomsoft Backup</h1>
            </div>
            <div className="card-body">

              <p>Este software oferece opção de efetuar o backup automaticamente do sistema</p>

              <a className="btn btn-lg btn-outline-primary" rel="noreferrer" aria-current="page" href="http://www.siscomsoft.com.br/downloads/setupBK.exe" target="_blank">Download</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Menu3 />
  </section>

}

export default Arquivos;
import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import api from '../services/api';
import Navbar from '../Components/Navbar/navbar';
import Menu3 from '../Components/Menu/menu3';

function NovaConta() {

    const [mensagem, setMensagem] = useState('');
    const [sucesso, setSucesso] = useState('');


   // const [id_usuario, setIdusuario] = useState();
    const [administrador, setAdministrador] = useState(false);
    const [nome_usuario, setNomeUsuario] = useState('');
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
   // const [confirmacao, setConfirmacao] = useState('');

    function cadastrarUsuario() {

        setMensagem('');
        if (nome_usuario.length === 0) {
            setMensagem('Informe nome usuário!');
            return
        }
        if (!login) {
            setMensagem('Informe usuário!');
            return
        }

        if (password.length === 0) {
            setMensagem('Informe senha!');
            return
        }

        //alert(nome_usuario);
        api.post('/usuarios', {
           // id_usuario: id_usuario,
            administrador: administrador,
            NOME_USUARIO: nome_usuario,
            login: login,
            password: password
        }
        )
            .then(res => {
                setMensagem('');
                setSucesso('S');
            }).catch(error => {
                setSucesso('N');
                setMensagem('Erro: '+error.mensagem);
            })
        // alert(administrador);
        //  alert(login);

    }
    return (
        <div>
            <Navbar />
            <div className="container-fluid  titulo">
                <div className=" col-lg-6">
                    <h1>Novo Usuário</h1>
                    <form>
                        <div className="row">
                            <div className="col-lg-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">Código</label>
                                <input id="id_usuario" type="text"  className="form-control" aria-describedby="emailHelp" disabled />
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <br />
                                    <input className="custom-control-input" name="administrador" type="checkbox"
                                        onChange={(e) => { setAdministrador(e.target.checked) }}
                                        checked={administrador} />

                                    <label className="form-check-label">
                                        Administrador
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Nome</label>
                            <input onChange={(e) => setNomeUsuario(e.target.value)} value={nome_usuario} type="text" className="form-control" id="nome_usuario" aria-describedby="emailHelp" />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Login</label>
                            <input onChange={(e) => setLogin(e.target.value)} value={login} type="email" className="form-control" id="login" aria-describedby="emailHelp" />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Senha</label>
                            <input onChange={(e) => setPassword(e.target.value)} value={password} type="password" className="form-control" id="passwordF" aria-describedby="emailHelp" />
                        </div>

                        <div className="text-center">
                            <Link to="/app/usuarioslist" className="btn btn-outline-primary btn-acao">Cancelar</Link>
                            <button onClick={cadastrarUsuario} type="button" className="btn btn-primary btn-acao">Salvar</button>
                        </div>

                        {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null}
                        {sucesso === 'S' ? <Redirect to='/app/usuarioslist' /> : null}

                    </form>
                </div>
            </div>
            <Menu3 />
        </div>
    )
}

export default NovaConta;
import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Navbar from '../Components/Navbar/navbar';
import './editarcliente.css';
import api from '../services/api';
import Menu3 from '../Components/Menu/menu3';
import MaskedInput from '../services/maskeinput';

function EditarCliente(props) {
  const [mensagem, setMensagem] = useState('');
  const [sucesso, setSucesso] = useState('');

  const [id_cliente, setIdcliente] = useState('');
  const [status, setStatus] = useState('A');
  const [tipo, setTipo] = useState('J');
  const [cpf_cnpj, setCpfcnpj] = useState('');
  const [razao_social, setRazaoSocial] = useState('');
  const [fantasia, setFantasia] = useState('');
  const [fone, setFone] = useState('');
  const [fax, setFax] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [bairro, setBairro] = useState('');
  const [cep, setCep] = useState('');
  const [contato, setContato] = useState('');

  const [id_cidade, setIdcidade] = useState(0);
  const [id_uf, setIduf] = useState(0);
  const [dias_lib, setDiaslib] = useState(0);

  const [ufs, setUfs] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [email, setEmail] = useState([]);

 // const [datainclusao, setDatainclusao] = useState('');
 




  /*  function buscaCidade(id) {
      
      api.get(`/cidades/?uf=${id}`)
        .then(res => {
          setCidades(res.data.data);
          //console.log(res.data.data);
         
        })
    }*/
  /*  function onSelect() {
      
      api.get(`/cidades/?uf=${id_uf}`)
        .then(res => {
          setCidades(res.data.data);
          //console.log(res.data.data);
         
        })
    }*/
  useEffect(() => {

    api.get(`/cidades/?uf=${id_uf}`)
      .then(res => {
        setCidades(res.data.data);
        //console.log(res.data.data);

      })

  }, [id_uf]);

  useEffect(() => {

    api.get('/ufs').then((resultado) => {
      setUfs(resultado.data.data);
      //console.log(ufs);
    })

  }, []);

  useEffect(() => {

    api.get(`/clientes/${props.match.params.id}`).then((resultado) => {

      //console.log(doc.idUsuario);

      console.log(resultado.data);
      
      setIdcliente(resultado.data.idCliente);
      setStatus(resultado.data.status);
      setTipo(resultado.data.tipo);
      setCpfcnpj(resultado.data.cpfCnpj);
      setRazaoSocial(resultado.data.razaoSocial);
      setFantasia(resultado.data.fantasia);
      setFone(resultado.data.fone);
      setFax(resultado.data.fax);
      setLogradouro(resultado.data.logradouro);
      setNumero(resultado.data.numero);
      setComplemento(resultado.data.complemento);
      setBairro(resultado.data.bairro);
      setCep(resultado.data.cep);
      setContato(resultado.data.contato);
      setIdcidade(resultado.data.idCidade);
      setIduf(resultado.data.idUf);
      setDiaslib(resultado.data.diasLib);
      setEmail(resultado.data.eMail);
      
    })

  }, [props.match.params.id]);


  function AlterarCliente() {

    if (razao_social.length === 0) {
      setMensagem('Informe Razação Social /nome');
    }
    else if (fantasia.length === 0) {
      setMensagem('Informe fantaisa');
    }
    else {
      // console.log(id_cliente);

     // console.log('data incluisao' + datainclusao);

      api.put(`/clientes/${id_cliente}`, {
        idCliente: id_cliente,
        RAZAO_SOCIAL: razao_social,
        fantasia: fantasia,
        fone: fone,
        fax: fax,
        status: status,
        tipo: tipo,
        CPF_CNPJ: cpf_cnpj,
        logradouro: logradouro,
        numero: numero,
        complemento: complemento,
        bairro: bairro,
        cep: cep,

        contato: contato,
       // data_inclusao: datainclusao,

        ID_CIDADE: id_cidade,
        ID_UF: id_uf,
        DIAS_LIB: dias_lib,
        E_MAIL: email

      }

      ).then(() => {
        // console.log('envio'+id_uf);
        // console.log('envio'+id_cidade);
        setMensagem('');
        setSucesso('S');
      }).catch((erro) => {
        setMensagem('Erro: ' + erro);
        setSucesso('N');
      })
    }
  }
  return <div>
    <Navbar />
    <div className="container-fluid titulo">

      <div className="col-lg-10">
        <h1>Editar Cliente</h1>
        <form>
          <div className="mb-3 col-lg-2">
            <label htmlFor="exampleInputEmail1" className="form-label">Código</label>
            <input id="id_usuario" type="text" value={props.match.params.id} className="form-control" aria-describedby="emailHelp" disabled />
          </div>
          <div className="row">
            <div className="col-lg-3">
              <label htmlFor="exampleInputEmail1" className="form-label">Status</label><br />
              <div className="form-check">
                <input className="form-check-input" type="radio" name="status"
                  value="A" onChange={(e) => setStatus(e.target.value)} checked={status === "A"} />
                <label className="form-check-label">
                  Ativo
                </label>
              </div>
              <div className="form-check">
                <input className="form-check-input" type="radio" name="status"
                  checked={status === "I"}
                  value="I" onChange={(e) => setStatus(e.target.value)} />
                <label className="form-check-label" >
                  Inativo
                </label>
              </div>
            </div>
            <div className="col-lg-3">
              <label htmlFor="exampleInputEmail1" className="form-label">Tipo</label><br />
              <div className="form-check">
                <input className="form-check-input" type="radio" name="categoria"
                  value="F" onChange={(e) => setTipo(e.target.value)} checked={tipo === "F"} />
                <label className="form-check-label">
                  Fisica
                </label>
              </div>
              <div className="form-check">
                <input className="form-check-input" type="radio" name="categoria"
                  checked={tipo === "J"}
                  value="J" onChange={(e) => setTipo(e.target.value)} />
                <label className="form-check-label" >
                  Jurídica
                </label>
              </div>

            </div>

            <div className="col-lg-6">
              {tipo === "J" ? <label>CNPJ</label> : <label>CPF</label>}
              {tipo === "J" ?
                <MaskedInput render="false"
                  name="cpfcnpj"
                  mask="99.999.999/9999-99"
                  value={cpf_cnpj}
                  onChange={(e) => setCpfcnpj(e.target.value)}
                  isRequired={true}
                /> :
                <MaskedInput
                  name="cpfcnpj"
                  mask="999.999.999-99"
                  value={cpf_cnpj}
                  onChange={(e) => setCpfcnpj(e.target.value)}
                  isRequired={true}

                />}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <label htmlFor="exampleInputEmail1" className="form-label">Razão Social/Nome</label>
              <input type="text" value={razao_social} onChange={(e) => setRazaoSocial(e.target.value)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>
            <div className="col-lg-6">
              <label htmlFor="exampleInputEmail1" className="form-label">Fantasia</label>
              <input type="text" value={fantasia} onChange={(e) => setFantasia(e.target.value)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>
          </div>


          <div className="row">
            <div className="col-lg-6">
              <label htmlFor="logradouro" className="form-label">Logradouro</label>
              <input type="text" value={logradouro} onChange={(e) => setLogradouro(e.target.value)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>

            <div className="col-lg-6">
              <label htmlFor="complemento" className="form-label">complemento</label>
              <input type="text" value={complemento} onChange={(e) => setComplemento(e.target.value)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <label htmlFor="logradouro" className="form-label">Bairro</label>
              <input type="text" value={bairro} onChange={(e) => setBairro(e.target.value)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>

            <div className="col-lg-3">
              <label htmlFor="complemento" className="form-label">Número</label>
              <input type="number" value={numero} onChange={(e) => setNumero(e.target.value)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>
            <div className="col-lg-3">
              <label htmlFor="complemento" className="form-label">Cep</label>
              <MaskedInput render="false"
                className="form-control"
                name="cep"
                mask="99999-999"
                value={cep}
                onChange={(e) => setCep(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <label htmlFor="logradouro" className="form-label">UF</label>
              <select name="uf" className="form-select"
                onChange={(e) => setIduf(e.target.value)}
                /*onClick={onSelect.bind(id_uf)}*/ value={id_uf}  >
                <option disabled={false} value="" >Selecione...</option>
                {ufs.map(estados => (
                  <option key={estados.idUf} value={estados.idUf}>{estados.idUf} - {estados.nomeUf}</option>
                ))}

              </select>
            </div>

            <div className="col-lg-6">
              <label htmlFor="complemento" className="form-label">Cidade</label>
              <label>Cidade</label>
              <select name="cidade" className="form-select" onChange={(e) => setIdcidade(e.target.value)} value={id_cidade} >
                <option disabled={false} value="">Selecione...</option>
                {cidades.map(cidade => (
                  <option key={cidade.idCidade} value={cidade.idCidade}>{cidade.nomeCidade}- {cidade.uf}</option>
                ))}

              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <label htmlFor="logradouro" className="form-label">Telefone</label>
              <MaskedInput render="false"
                name="fone"
                mask="(99)9999-9999"
                value={fone}
                onChange={(e) => setFone(e.target.value)}
              />
            </div>
            <div className="col-lg-3">
              <label htmlFor="logradouro" className="form-label">Celular</label>
              <MaskedInput render="false"
                name="fax"
                mask="(99)99999-9999"
                value={fax}
                onChange={(e) => setFax(e.target.value)}
              />
            </div>

            <div className="col-lg-6">
              <label htmlFor="complemento" className="form-label">Contato</label>
              <input type="text" value={contato} onChange={(e) => setContato(e.target.value)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <label htmlFor="logradouro" className="form-label">Qtd. Dias Liberado</label>
              <input value={dias_lib} type="number" onChange={(e) => setDiaslib(e.target.value)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>
            <div className="col-lg-9">
              <label htmlFor="email" className="form-label">E-mail</label>
              <input value={email} type="email" onChange={(e) => setEmail(e.target.value)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>

          </div>

          <div className="text-center">
            <Link to="/app/home" className="btn btn-outline-primary btn-acao">Cancelar</Link>
            <button type="button" onClick={AlterarCliente} className="btn btn-primary btn-acao">Salvar</button>
          </div>
          {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null}
          {sucesso === 'S' ? <Redirect to='/app/home' /> : null}

        </form>
      </div>
    </div>
    <Menu3 />
  </div>;
}

export default EditarCliente;
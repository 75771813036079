import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../services/api';
import Navbar from '../Components/Navbar/navbar';
import Menu3 from '../Components/Menu/menu3';
import './noticia.css';
import SweetAlert from 'react-bootstrap-sweetalert';
//import { data } from 'jquery';


const $ = require('jquery');
$.dataTable = require('datatables.net');

function NoticiaTable() {
    const [noticas, setNotica] = useState([]);
    const [excluido, setExcluido] = useState('');

    const [confirmacao, setConfirmacao] = useState(false);
    const [confirmacaoId, setConfirmacaoId] = useState('');

    useEffect(() => {
        let listaNoticia = [];

        api.get("/noticias").then(async function (resultado) {
            await resultado.data.data.forEach(function (doc) {
                //console.log(doc);
                //console.log(doc.dataCadastro);
                //const datatime = new Date(doc.dataCadastro);
                //console.log(datatime.getTimezoneOffset());
                listaNoticia.push({
                    id: doc.idNoticia,
                    titulo: doc.titulo,
                    dataCadastro: doc.dataCadastro,
                    dataEvento: doc.dataEvento,
                    descricaoResumida: doc.descricaoResumida,
                });
            })
            //console.log(resultado.data.data);

            setNotica(resultado.data.data);
            $(document).ready(function () {
                <font></font>
                $('#noticia').DataTable(
                    {destroy: true,
                       // searching: false,
                       retrieve: true,
                       paging: false,
                        "language": {
                            "lengthMenu": "Mostrar _MENU_ registros por página",
                            "zeroRecords": "Nada encontrado - desculpe",
                            "info": "Mostrando página _PAGE_ de _PAGES_",
                            "infoEmpty": "Nenhum registro disponível",
                            "infoFiltered": "(filtrado from _MAX_ total records)",
                            "search": "Buscar:",
                            "paginate": {
                                "first": "Primeiro",
                                "last": "Ultimo",
                                "next": "Próximo ",
                                "previous": "Anterior"
                            },
                        }
                    }


                ); <font></font>
            }); <font></font>

        }).catch((err) => {
            console.error("ops! ocorreu um erro" + err);
        });

    }, [excluido]);
    function deleteUser(id) {
        //alert('Exluir noticia ' + id);
        api.delete(`/noticias/${id}`).then(res => {
            setExcluido(id);
            setConfirmacao(false);
        })
    }
    function confirmDeleteUser(id) {
        setConfirmacaoId(id);
        setConfirmacao(true);
    }

    if (!noticas) return null;
    return (
        <div>
            <Navbar />
            <div className="offset-lg-1 col-lg-10 titulo" >

                <br></br>
                <h3>Lista de Noticias({noticas.length})</h3>
                <br></br>
                <div className="row">
                    <div className="col-sm-12">
                        <Link to="/app/novanoticia" className="btn btn-primary btn-acao" ><i className="fas fa-plus"></i> Noticias</Link>
                    </div>
                </div>
                <br></br>
                <table id="noticia" className="display" width="90%" >
                    <thead>
                        <tr className="table-secondary">
                            <th scope="col" >Código  </th>
                            <th scope="col" >Data Cadastro  </th>
                            <th scope="col" >Data Evento  </th>
                            <th scope="col" >Titúlo </th>
                            <th scope="col" >Ação </th>
                        </tr>
                    </thead>
                    <tbody>
                        {noticas.map((noticia) => {
                            return <tr key={noticia.idNoticia}>
                                <td scope="row">{noticia.idNoticia}</td>

                                <td >{new Date(noticia.dataCadastro + 'T12:00:00.00-0000').toLocaleDateString('pt-Br')}</td>
                                <td >{Intl.DateTimeFormat('pt-BR', { dateStyle: 'short' }).format(new Date(noticia.dataEvento + 'T12:00:00.00-0000'))}        </td>

                                <td> {noticia.titulo} </td>
                                <td>
                                    <Link to={'/app/editarnoticia/' + noticia.idNoticia}><i className="fas fa-edit icone-acao"></i></Link>
                                    <Link to='#' onClick={() => confirmDeleteUser(noticia.idNoticia)}><i className="far fa-trash-alt icone-acao red"></i></Link>
                                </td>

                            </tr>
                        })
                        }
                    </tbody>

                </table>
                {
                    confirmacao ? <SweetAlert
                        warning
                        showCancel
                        showCloseButton
                        confirmBtnText="Sim"
                        confirmBtnBsStyle="danger"
                        cancelBtnText="Não"
                        cancelBtnBsStyle="light"
                        title="Exclusão"
                        onConfirm={() => deleteUser(confirmacaoId)}
                        onCancel={() => setConfirmacao(false)}
                        reverseButtons={true}
                    >
                        Deseja excluir a noticia selecionado?
                    </SweetAlert> : null}
            </div>
            <Menu3 />
        </div>
    )
}
export default NoticiaTable;



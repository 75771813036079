import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Navbar from '../Components/Navbar/navbar';
import Menu3 from '../Components/Menu/menu3';
import api from '../services/api';
import MaskedInput from '../services/maskeinput';
import './noticia.css';


function NovaNoticia() {
    const [mensagem, setMensagem] = useState('');
    const [sucesso, setSucesso] = useState('');

    //const [id_noticia, setIdNoticia] = useState();
    const [titulo, setTitulo] = useState('');
    const [caminhoFotoGrande, setCaminhoFotoG] = useState('');
    const [caminhoFotoPequena, setCaminhoFotoP] = useState('');
    const [dataCadastro, setDataCadastro] = useState(new Date().toLocaleDateString('pt-Br'));
    const [dataEvento, setDataEvento] = useState('');
    const [descricaoCompleta, setDescricaoCompleta] = useState('');
    const [descricaoResumida, setDescricaoResumida] = useState('');
    const [cpfCnpj, setCpfCnpj] = useState('');


    function CadastrarNoticia() {

        if (dataEvento.length === 0) {
            setMensagem('Informe da data do evento');
        } else

            if (titulo.length === 0) {
                setMensagem('Informe o titulo');
            }
            else if (descricaoResumida.length === 0) {
                setMensagem('Informe o descricao resumida');
            }
            else {
                //alert(nome);

                api.post('/noticias', {

                    titulo: titulo,
                    caminhoFotoGrande: caminhoFotoGrande,
                    caminhoFotoPequena: caminhoFotoPequena,
                    dataCadastro: dataCadastro,
                    dataEvento: dataEvento,
                    descricaoCompleta: descricaoCompleta,
                    descricaoResumida: descricaoResumida,
                    cpfCnpj: cpfCnpj
                })
                    .then(res => {
                        setMensagem('');
                        setSucesso('S');
                    }).catch(error => {
                        setSucesso('N');
                        //console.log(error.message);
                        setMensagem('Erro: ' + error)
                    })
            }

    }


    return <div>
        <Navbar />
        <div className="container-fluid titulo">
            <div className="col-lg-10">
                <h1>Noticia Usuário</h1>
                <form>
                    <div className="row">
                        <div className=" col-lg-6">
                            <label htmlFor="exampleInputEmail1" className="form-label">Código</label>
                            <input id="id_usuario" type="text" className="form-control" aria-describedby="emailHelp" disabled />
                        </div>
                        <div className="col-lg-6">
                            <label htmlFor="exampleInputEmail1" className="form-label">Data cadastro</label>
                            <input type="text" value={dataCadastro} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <label htmlFor="exampleInputEmail1" className="form-label">Titulo</label>
                            <input type="text" onChange={(e) => setTitulo(e.target.value)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                        </div>
                        <div className="col-lg-6">
                            <label htmlFor="exampleInputEmail1" className="form-label">Descrição resumida</label>
                            <input type="text" onChange={(e) => setDescricaoResumida(e.target.value)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <label htmlFor="exampleInputEmail1" className="form-label">Data evento</label>
                            <input type="date" required='true' onChange={(e) => setDataEvento(e.target.value)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                        </div>
                        <div className="col-lg-6">
                            <label htmlFor="exampleInputEmail1" className="form-label">CNPJ</label>
                            <MaskedInput render="false"
                                name="fone"
                                mask="99.999.999/9999-99"
                                value={cpfCnpj == null ? '' : cpfCnpj}
                                onChange={(e) => setCpfCnpj(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <label htmlFor="exampleInputEmail1" className="form-label">Caminho foto pequena</label>
                            <input type="text" onChange={(e) => setCaminhoFotoP(e.target.value)} className="form-control" />
                        </div>
                        <div className="col-lg-6">
                            <label htmlFor="exampleInputEmail1" className="form-label">Caminho foto grande</label>
                            <input type="text" onChange={(e) => setCaminhoFotoG(e.target.value)} className="form-control" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <label htmlFor="exampleInputEmail1" className="form-label">Descrição  completa</label>
                            <textarea type="text" onChange={(e) => setDescricaoCompleta(e.target.value)} className="form-control text-area" id="exampleInputEmail1" aria-describedby="emailHelp" />
                        </div>
                    </div>
                    <div className="text-center">
                        <Link to="/app/noticiaslist" className="btn btn-outline-primary btn-acao">Cancelar</Link>
                        <button type="button" onClick={CadastrarNoticia} className="btn btn-primary btn-acao">Salvar</button>
                    </div>
                    {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null}
                    {sucesso === 'S' ? <Redirect to='/app/noticiaslist' /> : null}
                </form>
            </div>
        </div>
        <Menu3 />
    </div>
}
export default NovaNoticia;

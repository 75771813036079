import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../Context/contextAuth';
import './menu.css';

const Menu3 = () => {
    const { admin } = useContext(AuthContext);

    //const logado = context.isLogger;

    if (admin) {
        return (
            <nav id="sidebarMenu" className="bg-light d-lg-block collapse  menuLateral " >
                <div className="position-sticky pt-3 ">
                    <ul className="nav flex-column  ">
                        <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                            <span>Menu Sistema </span>
                            <a className="link-secondary" href="/#" aria-label="Add a new report">
                                <span data-feather="plus-circle"></span>
                            </a>
                        </h6>

                        <li className="active  ">

                            <a href="#homeSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle menuItem" >Cadastro</a>
                            <ul className="collapse list-unstyled" id="homeSubmenu">
                                <li className="nav-item">
                                    <Link to={'/'} className="nav-link active"><i className="fas fa-home"></i> Principal</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={'/app/usuarioslist'} className="nav-link"> <i className="fas fa-user-friends"></i> Usuários</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={'/app/home'} className="nav-link"><i className="fas fa-address-card"></i> Clientes</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={'/app/noticiaslist'} className="nav-link"><i className="fa fa-newspaper-o"></i> Noticias</Link>
                                </li>
                            </ul>
                        </li>
                        <li className=" active ">
                            <a href="#MovSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle menuItem">Movimentação</a>
                            <ul className="collapse list-unstyled" id="MovSubmenu">

                                <li className="nav-item">
                                    <Link to={'/app/arquivos'} className="nav-link"><i className="fas fa-file-download"></i> Downloads</Link>
                                </li>

                            </ul>
                        </li>
                    </ul>
                </div>
            </nav>

        )
    } else {
        return (

            <nav id="sidebarMenu" className="bg-light d-md-block collapse menuLateral">
                <div className="position-sticky pt-3">
                    <ul className="nav flex-column">
                        <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                            <span>Cadastro</span>
                            <a className="link-secondary" href="/#" aria-label="Add a new report">
                                <span data-feather="plus-circle"></span>
                            </a>
                        </h6>

                        <li className="nav-item">
                            <Link to={'/'} className="nav-link active"><i className="fas fa-home"></i> Principal</Link>
                        </li>

                        <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                            <span>Movimentação</span>
                            <a className="link-secondary" href="/#" aria-label="Add a new report">
                                <span data-feather="plus-circle"></span>
                            </a>
                        </h6>
                        <li className="nav-item">
                            <Link to={'/app/arquivos'} className="nav-link"><i className="fas fa-file-download"></i> Downloads</Link>
                        </li>
                    </ul>

                </div>
            </nav>

        );
    }


}


export default Menu3;
import React, { useState, useContext } from 'react';
import './login.css';
import api from '../services/api';
import auth from '../services/auth';
import { Redirect } from 'react-router-dom';
import {AuthContext} from '../Context/contextAuth.jsx';
function Login() {
  
  const [sucesso, setSucesso] = useState('');
  const [myUser, setUser] = useState();
  
  const {setLogado} = useContext(AuthContext);
  const {setAdmin} = useContext(AuthContext);

  const [redirecionar, setRedirecionar] = useState('');

  const handleInputChange = ev => {
    let name = [ev.target.name];
    let value = ev.target.value;
    setUser(
      {
        ...myUser, [name]: value
      }
    );
    //console.log(ev.target.value);
  }
  function LoginUsuario() {
    auth.post('/usuarios/auth', myUser)
      .then(res => {
       localStorage.setItem('token-siscom', res.data.Token);
       localStorage.setItem("logado",'S');
       
       localStorage.setItem('username', myUser.username);
       
       //console.log(res.data.admin);
       
        
        setLogado(true);
        if (res.data.admin === true){
          localStorage.setItem("admin", "S");
          setAdmin(true);
         // console.log(res.data.admin)
          //console.log(localStorage.getItem('admin'));
          setRedirecionar(<Redirect to='/app/home'/>);
        }else
        {
          setAdmin(false);
          localStorage.setItem("admin", "N");
          setRedirecionar(<Redirect to='/app/homeb'/>)
        }

        setSucesso('S');
       // console.log('RETORNO '+ res.data.Token);
        //console.log('login '+localStorage.getItem('token-siscom'));
        api.defaults.headers.common = {Authorization :`bearer ${localStorage.getItem('token-siscom')}`};
      })
      .catch(error => {
        setLogado(false);
        localStorage.setItem("logado",'N');
        localStorage.setItem("admin", "N");
        setAdmin(false);
        setSucesso('N');
        //console.log(error);

      })
  }
  return <div className="d-flex align-items-center text-center form-container">
    <form className="form-signin">
      <img className="mb-4" src="Images/Logo.png" alt="" />
      <h1 className="h3 mb-3 fw-normal">Login</h1>

      <div className="form-floating">
        <input name="username" type="email" onChange={handleInputChange} className="form-control" id="floatingInput" placeholder="Usuário" />
        <label >Usuário</label>
      </div>

      <div className="form-floating">
        <input name="password" type="password" onChange={handleInputChange} className="form-control" id="floatingPassword" placeholder="Senha" />
        <label >Senha</label>
      </div>

      <button onClick={LoginUsuario} className="w-100 btn btn-lg btn-primary" type="button">Acessar</button>
      {
        // sucesso === 1 ? 'faca isso' : 'faca aquilo'
        sucesso === 'N' ? <div className="alert alert-danger mt-2" role="alert">Usuário ou senha inválida.</div> : null}
       
       
       {/*redirecionar*/}
       {(sucesso ==='S') ? redirecionar : null }

      <p className="mt-5 mb-3 text-muted">&copy; Desenvolvido por Siscomsoft</p>
    </form>
  </div>
}

export default Login;


import React, {useState} from 'react';

const AuthContext = React.createContext({});

function AuthProvider(props){
    let isLogado = localStorage.getItem("logado");
    let isAdmin = localStorage.getItem("admin");

    const [logado, setLogado] = useState(isLogado === "S" ? true : false);
    const [admin, setAdmin] = useState(isAdmin === "S" ? true : false);
    
    return (
        <AuthContext.Provider value={{logado, setLogado, admin, setAdmin}}>
            {props.children}
        </AuthContext.Provider>
    )
}

export {AuthContext, AuthProvider};
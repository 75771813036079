import React from 'react';
import './listacliente.css';
import { Link } from 'react-router-dom';
//import clientes from '../../Dados/clientes';

function ListaClientes(props) {



    return <div className="offset-lg-0 col-lg-10">
        <h1>Cadastro de Clientes</h1>

        <div className="row">
            <div className="col-1">
                <Link to='/app/novocliente' className="btn btn-primary btn-acao" type="button"><i className="fas fa-plus"></i> Cliente</Link>
            </div>

        </div>
        <table id="cliente" className="display" width="100%" >

            <thead>
                <tr className="table-secondary">
                    <th className="col-1" scope="col">Código</th>
                    <th className="col-4" scope="col" >Nome</th>
                    <th className="col-3" scope="col" >Fantasia</th>
                    <th scope="col">CPF/CNPJ</th>
                    <th scope="col">Ativo</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>

                {
                    props.arrayClientes.map((cliente) => {
                        return <tr key={cliente.id}>
                            <td >{cliente.id}</td>
                            <td >{cliente.nome}</td>
                            <td >{cliente.fantasia}</td>
                            <td>{cliente.cpfcnpj}</td>
                            <td>
                                {cliente.status === "A" ? <label>Ativo</label> : <label>Inativo</label>}
                            </td>

                            <td>
                                <Link to={'/app/editarcliente/' + cliente.id}><i className="fas fa-edit icone-acao"></i></Link>
                                <Link to='#' onClick={() => props.clickDelete(cliente.id)}><i className="far fa-trash-alt icone-acao red"></i></Link>

                            </td>
                        </tr>
                    })
                }


            </tbody>
        </table>
    </div>
}

export default ListaClientes;

import React from 'react';

function Banner() {
    return <section id="banner">
        <div className="container">
            <div className="row">

                <div className="col-lg-6">
                    <h1>ERP completo, flexível, simples de usar,  atendemos Supermercados,
                        distribuidoras,Agropecuária, atacadistas, Bares, Restaurantes,
                         Pizzarias,Lojas de conveniência, entre outros...!</h1>
                    <h4>Gerencie seu negocio com segurança e agilidade!</h4>

                </div>

                <div className="col-lg-6">
                    <img alt="img" src="Images/screenshot-crm.png" />
                </div>

            </div>
        </div>
    </section>;

}

export default Banner;
import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Navbar from '../Components/Navbar/navbar';
import Menu3 from '../Components/Menu/menu3';
import api from '../services/api';
import MaskedInput from '../services/maskeinput';

import './novocliente.css';

function NovoCliente() {

  const [status, setStatus] = useState('A');
  const [tipo, setTipo] = useState('J');
  const [cpfcnpj, setCpfcnpj] = useState('');
  const [nome, setNome] = useState('');
  const [fantasia, setFantasia] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [bairro, setBairro] = useState('');
  const [cep, setCep] = useState('');
  const [fone, setFone] = useState('');
  const [fax, setFax] = useState('');
  const [contato, setContato] = useState('');
  const [datainclusao,setDatainclusao] = useState('');

  const [idcidade, setIdcidade] = useState(0);
  const [iduf, setIduf] = useState(0);
  const [diaslib, setDiaslib] = useState(0);
  const [email, setEmail] = useState([]);

  const [mensagem, setMensagem] = useState('');
  const [sucesso, setSucesso] = useState('N');

  const [ufs, setUfs] = useState([]);
  const [cidades, setCidades] = useState([]);

  function onSelect() {
    api.get(`/cidades/?uf=${iduf}`)
      .then(res => {
        setCidades(res.data.data);
        //console.log(res.data.data);
        // console.log(iduf);
      })
  }
  useEffect(() => {

    api.get('/ufs').then((resultado) => {
      setUfs(resultado.data.data);
      //console.log(ufs);
      
      var dataAtual = new Date();
      var dia = dataAtual.getDate();
      var ano = dataAtual.getFullYear();
      var mes = dataAtual.getMonth() + 1;

      //console.log(dia);
      //console.log(ano);
      //console.log(mes);

      const datanova = (ano + '-' + mes + '-' + dia);
      //console.log('data formatada:' + datanova);
      setDatainclusao(datanova);
    })

  }, []);

  function CadastrarCliente() {
    if (nome.length === 0) {
      setMensagem('Informe o nome');
    }
    else if (fantasia.length === 0) {
      setMensagem('Informe o fantasia');
    }
    else
      if (cpfcnpj.length === 0) {
        setMensagem('Informe o CPF/CNPJ');
      } else {
        //alert(nome);

        api.post('/clientes', {
          status: status,
          tipo: tipo,
          //cpf_cnpj: cpfcnpj,
          CPF_CNPJ: cpfcnpj,
          RAZAO_SOCIAL: nome,
          fantasia: fantasia,
          logradouro: logradouro,
          numero: numero,
          complemento: complemento,
          bairro: bairro,
          cep: cep,
          fone: fone,
          fax: fax,
          contato: contato,
          DATA_INCLUSAO :datainclusao,
          ID_CIDADE: idcidade,
          ID_UF: iduf,
          DIAS_LIB: diaslib,
          E_MAIL : email
        })
          .then(res => {
            setMensagem('');
            setSucesso('S');
          }).catch(error => {
            setSucesso('N');
            //console.log(error.message);
            if (error.message === 'Request failed with status code 406') {
              setMensagem('Cliente já cadastrado');
            } else { setMensagem('Erro: ' + error) }

          })
      }
  }
  return <div>
    <Navbar />
    <div className="container-fluid  titulo">

      <div className="col-lg-10">
        <h1>Novo Cliente</h1>
        <form>
          <div className="row">
            <div className="col-lg-3">
              <label htmlFor="exampleInputEmail1" className="form-label">Status</label><br />
              <div className="form-check">
                <input className="form-check-input" type="radio" name="status"
                  value="A" onChange={(e) => setStatus(e.target.value)} checked={status === "A"} />
                <label className="form-check-label">
                  Ativo
                </label>
              </div>
              <div className="form-check">
                <input className="form-check-input" type="radio" name="status"
                  checked={status === "I"}
                  value="I" onChange={(e) => setStatus(e.target.value)} />
                <label className="form-check-label" >
                  Inativo
                </label>
              </div>
            </div>
            <div className="col-lg-3">
              <label htmlFor="exampleInputEmail1" className="form-label">Tipo</label><br />
              <div className="form-check">
                <input className="form-check-input" type="radio" name="categoria"
                  value="F" onChange={(e) => setTipo(e.target.value)} checked={tipo === "F"} />
                <label className="form-check-label">
                  Fisica
                </label>
              </div>
              <div className="form-check">
                <input className="form-check-input" type="radio" name="categoria"
                  checked={tipo === "J"}
                  value="J" onChange={(e) => setTipo(e.target.value)} />
                <label className="form-check-label" >
                  Jurídica
                </label>
              </div>

            </div>

            <div className="col-lg-6">
              {tipo === "J" ? <label>CNPJ</label> : <label>CPF</label>}
              {tipo === "J" ?
                <MaskedInput render="false"
                  name="cpfcnpj"
                  mask="99.999.999/9999-99"
                  value={cpfcnpj}
                  onChange={(e) => setCpfcnpj(e.target.value)}
                  isRequired={true}
                /> :
                <MaskedInput
                  name="cpfcnpj"
                  mask="999.999.999-99"
                  value={cpfcnpj}
                  onChange={(e) => setCpfcnpj(e.target.value)}
                  isRequired={true}

                />}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <label htmlFor="exampleInputEmail1" className="form-label">Razão Social/Nome</label>
              <input type="text" onChange={(e) => setNome(e.target.value)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>
            <div className="col-lg-6">
              <label htmlFor="exampleInputEmail1" className="form-label">Fantasia</label>
              <input type="text" onChange={(e) => setFantasia(e.target.value)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <label htmlFor="logradouro" className="form-label">Logradouro</label>
              <input type="text" onChange={(e) => setLogradouro(e.target.value)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>

            <div className="col-lg-6">
              <label htmlFor="complemento" className="form-label">complemento</label>
              <input type="text" onChange={(e) => setComplemento(e.target.value)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <label htmlFor="logradouro" className="form-label">Bairro</label>
              <input type="text" onChange={(e) => setBairro(e.target.value)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>

            <div className="col-lg-3">
              <label htmlFor="complemento" className="form-label">Número</label>
              <input type="text" onChange={(e) => setNumero(e.target.value)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>
            <div className="col-lg-3">
              <label htmlFor="complemento" className="form-label">Cep</label>
              <MaskedInput render="false"
                className="form-control"
                name="cep"
                mask="99999-999"
                value={cep}
                onChange={(e) => setCep(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <label htmlFor="logradouro" className="form-label">UF</label>
              <select name="uf" className="form-select"
                onChange={(e) => setIduf(e.target.value)}
                onClick={onSelect.bind(iduf)} value={iduf}  >
                <option disabled={false} value="" >Selecione...</option>
                {ufs.map(estados => (
                  <option key={estados.idUf} value={estados.idUf}>{estados.idUf} -  {estados.nomeUf}</option>
                ))}

              </select>
            </div>

            <div className="col-lg-6">
              <label htmlFor="complemento" className="form-label">Cidade</label>
              <label>Cidade</label>
              <select name="cidade" className="form-select" onChange={(e) => setIdcidade(e.target.value)} value={idcidade} >
                <option disabled={false} value="">Selecione...</option>
                {cidades.map(cidade => (
                  <option key={cidade.idCidade} value={cidade.idCidade}>{cidade.nomeCidade}- {cidade.uf}</option>
                ))}

              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <label htmlFor="logradouro" className="form-label">Telefone</label>
              <MaskedInput render="false"
                name="fone"
                mask="(99)9999-9999"
                value={fone}
                onChange={(e) => setFone(e.target.value)}
              />
            </div>
            <div className="col-lg-3">
              <label htmlFor="logradouro" className="form-label">Celular</label>
              <MaskedInput render="false"
                name="fax"
                mask="(99)99999-9999"
                value={fax}
                onChange={(e) => setFax(e.target.value)}
              />
            </div>

            <div className="col-lg-6">
              <label htmlFor="complemento" className="form-label">Contato</label>
              <input type="text" onChange={(e) => setContato(e.target.value)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <label htmlFor="logradouro" className="form-label">Qtd. Dias Liberado</label>
              <input type="number" onChange={(e) => setDiaslib(e.target.value)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>
            <div className="col-lg-9">
              <label htmlFor="email" className="form-label">E-mail</label>
              <input value={email} type="email" onChange={(e) => setEmail(e.target.value)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>

          </div>


          <div className="text-center">
            <Link to="/app/home" className="btn btn-outline-primary btn-acao">Cancelar</Link>
            <button type="button" onClick={CadastrarCliente} className="btn btn-primary btn-acao">Salvar</button>
          </div>
          {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null}
          {sucesso === 'S' ? <Redirect to='/app/home' /> : null}

        </form>
      </div>
    </div>
    <Menu3 />
  </div>;
}

export default NovoCliente;
import React, { useState, useEffect } from 'react';
//import { Link } from 'react-router-dom';
import Navbar from '../Components/Navbar/navbar';
import Menu3 from '../Components/Menu/menu3';
import ListaClientes from '../Components/ListaCliente/listacliente';
import api from '../services/api.js';

import './home.css';
//import '../Components/Menu/style.css';
import SweetAlert from 'react-bootstrap-sweetalert';

const $ = require('jquery');
$.dataTable = require('datatables.net');

function Home() {



  const [clientes, setClientes] = useState([]);
  //const [busca, setBusca] = useState('');
  //const [texto, setTexto] = useState('');
  const [excluido, setExcluido] = useState('');

  const [confirmacao, setConfirmacao] = useState(false);
  const [confirmacaoId, setConfirmacaoId] = useState('');

  function deleteCliente(id) {
    //alert('Exluir CLIENTE ' + id);
    api.delete(`/clientes/${id}`).then(res => {
      setExcluido(id);
      setConfirmacao(false);
    })
  }
  function confirmDeleteUser(id) {
    setConfirmacaoId(id);
    setConfirmacao(true);
  }
  useEffect(() => {
    let listaCli = [];
    api.get("/clientes").then(async function (resultado) {
      await resultado.data.data.forEach(function (doc) {
        //console.log(doc.idCliente);
        //  if (doc.razaoSocial.indexOf(busca) >= 0) {
        // if (doc.razaoSocial.indexOf() >= 0) {
        listaCli.push({
          id: doc.idCliente,
          nome: doc.razaoSocial,
          fantasia: doc.fantasia,
          fone: doc.fone,
          status: doc.status,
          cpfcnpj: doc.cpfCnpj
        });
        //} 
      })

      setClientes(listaCli);

      $(document).ready(function () {
        <font></font>
        $('#cliente').DataTable(
          {retrieve: true,
            paging: false,
            "language": {
              "lengthMenu": "Mostrar _MENU_ registros por página",
              "zeroRecords": "Nada encontrado - desculpe",
              "info": "Mostrando página _PAGE_ de _PAGES_",
              "infoEmpty": "Nenhum registro disponível",
              "infoFiltered": "(filtrado from _MAX_ total records)",
              "search": "Buscar:",
              "paginate": {
                "first": "Primeiro",
                "last": "Ultimo",
                "next": "Próximo ",
                "previous": "Anterior"
              },
            }
          }

        ); <font></font>
      }); <font></font>

    })
  }, [excluido]);
  // }, [busca, excluido]);

  return <div>
    <Navbar />
    <div className="container-fluid col-lg-10 titulo">

      <ListaClientes arrayClientes={clientes} clickDelete={confirmDeleteUser} />
      {
        confirmacao ? <SweetAlert
          warning
          showCancel
          showCloseButton
          confirmBtnText="Sim"
          confirmBtnBsStyle="danger"
          cancelBtnText="Não"
          cancelBtnBsStyle="light"
          title="Exclusão"
          onConfirm={() => deleteCliente(confirmacaoId)}
          onCancel={() => setConfirmacao(false)}
          reverseButtons={true}
        >
          Deseja excluir o cliente selecionado?
        </SweetAlert> : null}
    </div>
    <Menu3 />
  
  </div>
}

export default Home;
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import './navbar.css';
import { AuthContext } from '../../Context/contextAuth.jsx';

function Navbar() {
  const { setLogado } = useContext(AuthContext);
  const { setAdmin } = useContext(AuthContext);

  function Logout() {
    setLogado(false);
    setAdmin(false);
    localStorage.removeItem("logado");
    localStorage.removeItem("admin");
    localStorage.removeItem("token-siscom");

  }

  return (//  navbar-dark

    <header className="navbar fixed-top  navbar-dark sticky-top flex-md-nowrap p-0 shadow">
      <Link className="navbar-brand logo" to="/app/home">
        <img src="../Images/logo.png" alt="Logo" height="48" />
      </Link>

      <button className="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>

      <ul className="navbar-nav px-3 logou">
        <li className="nav-item text-nowrap">
          <a href="/app" onClick={Logout} className="nav-link logout" aria-current="page" >Usuário: {localStorage.getItem('username')} - Sair</a>

        </li>
      </ul>
    </header>
  )
}

export default Navbar;
import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Navbar from '../Components/Navbar/navbar';
import './editarnoticia.css';
import api from '../services/api';
import Menu3 from '../Components/Menu/menu3';
import MaskedInput from '../services/maskeinput';

function EditarNoticia(props) {
    const [mensagem, setMensagem] = useState('');
    const [sucesso, setSucesso] = useState('');

    const [id_noticia, setIdNoticia] = useState();
    const [titulo, setTitulo] = useState('');
    const [caminhoFotoGrande, setCaminhoFotoG] = useState('');
    const [caminhoFotoPequena, setCaminhoFotoP] = useState('');
    const [dataCadastro, setDataCadastro] = useState('');
    const [dataEvento, setDataEvento] = useState('');
    const [descricaoCompleta, setDescricaoCompleta] = useState('');
    const [descricaoResumida, setDescricaoResumida] = useState('');
    const [cpfCnpj, setCpfCnpj] = useState('');
    
    
    useEffect(() => {

        api.get(`/noticias/${props.match.params.id}`).then((resultado) => {
            //console.log(resultado.data.idUsuario);
            // console.log(resultado.data);
            setIdNoticia(resultado.data.idNoticia);
            setTitulo(resultado.data.titulo);
            setCaminhoFotoG(resultado.data.caminhoFotoGrande);
            setCaminhoFotoP(resultado.data.caminhoFotoPequena);
            setDataCadastro(resultado.data.dataCadastro);
            setDataEvento(resultado.data.dataEvento);
            setDescricaoCompleta(resultado.data.descricaoCompleta);
            setDescricaoResumida(resultado.data.descricaoResumida);
            setCpfCnpj(resultado.data.cpfCnpj);
        })

    }, [props.match.params.id]);
    function AlterarNoticia() {
        if (dataEvento.length === 0){
            setMensagem('Informe da data do evento');
        }else
        if (titulo.length === 0) {
            setMensagem('Informe o Titúlo');
        }
        else {
            //console.log(id_usuario);
            api.put(`/noticias/${id_noticia}`, {
                id_noticia: id_noticia,
                titulo: titulo,
                caminhoFotoGrande: caminhoFotoGrande,
                caminhoFotoPequena: caminhoFotoPequena,
                dataCadastro: dataCadastro,
                dataEvento: dataEvento,
                descricaoCompleta: descricaoCompleta,
                descricaoResumida: descricaoResumida,
                cpfCnpj  : cpfCnpj
            }
            ).then(() => {
                setMensagem('');
                setSucesso('S');
            }).catch((erro) => {
                setMensagem('Erro: ' + erro);
                setSucesso('N');
            })
        }
    }
    return <div>
        <Navbar />
        <div className="container-fluid titulo">
            <div className="col-lg-10">
                <h1>Editar Noticia</h1>
                <form>
                    <div className="row">
                        <div className=" col-lg-6">
                            <label htmlFor="exampleInputEmail1" className="form-label">Código</label>
                            <input id="id_usuario" type="text" value={props.match.params.id} className="form-control" aria-describedby="emailHelp" disabled />
                        </div>
                        <div className="col-lg-6">
                            <label htmlFor="exampleInputEmail1" className="form-label">Data cadastro</label>
                            <input type="text" value={dataCadastro} onChange={(e) => setDataCadastro(e.target.value)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <label htmlFor="exampleInputEmail1" className="form-label">Titulo</label>
                            <input type="text" value={titulo} onChange={(e) => setTitulo(e.target.value)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                        </div>
                        <div className="col-lg-6">
                            <label htmlFor="exampleInputEmail1" className="form-label">Descrição resumida</label>
                            <input type="text" value={descricaoResumida} onChange={(e) => setDescricaoResumida(e.target.value)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <label htmlFor="exampleInputEmail1" className="form-label">Data evento</label>
                            <input type="date" required="true" value={dataEvento} onChange={(e) => setDataEvento(e.target.value)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                        </div>
                        <div className="col-lg-6">
                            <label htmlFor="exampleInputEmail1" className="form-label">CNPJ</label>
                            <MaskedInput render="false"
                                name="fone"
                                mask="99.999.999/9999-99"
                                value={cpfCnpj == null ? '' : cpfCnpj }
                                onChange={(e) => setCpfCnpj(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-lg-6">
                            <label htmlFor="exampleInputEmail1" className="form-label">Caminho foto pequena</label>
                            <input type="text" value={caminhoFotoPequena == null ? '' : caminhoFotoPequena} onChange={(e) => setCaminhoFotoP(e.target.value)} className="form-control"  />
                        </div>
                        <div className="col-lg-6">
                            <label htmlFor="exampleInputEmail1" className="form-label">Caminho foto grande</label>
                            <input type="text" value={caminhoFotoGrande == null ? '' :caminhoFotoGrande} onChange={(e) => setCaminhoFotoG(e.target.value)} className="form-control"  />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <label htmlFor="exampleInputEmail1" className="form-label">Descrição completa</label>
                            <textarea type="text" value={descricaoCompleta} onChange={(e) => setDescricaoCompleta(e.target.value)} className="form-control text-area" id="exampleInputEmail1" aria-describedby="emailHelp" />
                        </div>
                    </div>
                    <div className="text-center">
                        <Link to="/app/noticiaslist" className="btn btn-outline-primary btn-acao">Cancelar</Link>
                        <button type="button" onClick={AlterarNoticia} className="btn btn-primary btn-acao">Salvar</button>
                    </div>
                    {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null}
                    {sucesso === 'S' ? <Redirect to='/app/noticiaslist' /> : null}
                </form>
            </div>
        </div>

        <Menu3 />
    </div>

}
export default EditarNoticia;
import React from 'react';

function Features(){
    return <section id="features">
        <div className="container">
            <div className="row">



                <div className="col-lg-4 feature-box">
                    <i className="icon fas fa-hand-holding-heart fa-5x"></i>
                    <h3>Sistemas sem complicação</h3>                    
                    <p>O sistema possui uma interface intuitiva, muito simples e fácil de utilizar.</p>
                </div>

                <div className="col-lg-4 feature-box">
                <i className="icon fas fa-columns fa-5x"></i>
                    <h3>Sistemas especificos</h3>
                    <p>Quer desenvolver seu negócio mas não sabe como? Precisa de ajuda para planejar ou executar o seu próximo projeto? Deixe-nos ajudá-lo.
                         Qualquer empresa pode progredir a partir de pequenas mudanças, mas construir um futuro neste ambiente em rápida evolução, significa assumir riscos e tomar decisões perspicazes.</p>
                </div>

                <div className="col-lg-4 feature-box">
                    <i className="icon fas fas fa-shopping-cart fa-5x"></i>
                    <h3>Sistema Completo</h3>
                    <p>Sistemas para Supermercados,Distribuidores,Agropecuária, Atacadistas,Industria, Bares, Restaurantes,Pizzarias,
                        Força de venda, entre outros,
                        atende a legislação vigente para empresas do MEI, simples nacional, lucro Real e presumido</p>
                </div>
            </div>
            <div className="row">

                <div className="col-lg-4 feature-box">
                    <i className="icon fab fa-cc-amex fa-5x"></i>
                    <h3>Modulo TEF dedicado</h3>                    
                    <p>Integração com Software Express oferecendo mais agilidade e segurança com os meios de pagamento,
                        tenha opção Pix direto no seu PDV
                    </p>
                </div>

                <div className="col-lg-4 feature-box">
                    <i className="icon fas fa-globe-americas fa-5x"></i>
                    <h3>Integração com Ifood</h3>
                    <p>Agiliza o recebimento de pedidos direto no seu sistema evitando erros e reprocessos</p>
                </div>

                <div className="col-lg-4 feature-box">
                    <i className="icon fas fa-chart-bar fa-5x"></i>
                    <h3>Relatórios e Gráficos</h3>
                    <p>Diversos relatórios e Graficos intuitivos para auxiliar na tomada de decisão,
                        lucratividade, ticket médio, curva abc, Comissão de vendedores,equilibrio financeiro...
                    </p>
                </div>
                       
            </div>
        </div>
    </section>;
  }

export default Features;
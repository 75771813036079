import axios from 'axios';


//const api = axios.create ({baseURL:'http://127.0.0.1:8000'});
//const api = axios.create ({baseURL:'http://216.144.247.120:8000'});
//const api = axios.create ({baseURL:'http://172.107.85.174:8000'});
//const api = axios.create ({baseURL:'http://api.siscomsoft.com.br:8000'});

const api = axios.create ({baseURL:'https://api.siscomsoft.com.br'});



api.defaults.headers.common = {Authorization :`bearer ${localStorage.getItem('token-siscom')}`};

//console.log('api_toke');
//console.log('API ==>> '+ localStorage.getItem('token-siscom')); 

export default api;
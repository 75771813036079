import React, { useContext } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

/* Paginas */
import Site from './site/site.jsx';
import Login from './app/Login/login.jsx';
import Home from './app/Home/home.jsx';
import Homeb from './app/Home/homeb.jsx';
import NovaConta from './app/usuario/novousuario.js';
import UserTable from './app/usuario/listausuario.js';
import NoticiaTable from './app/notica/listanoticia.js';
import Novocliente from './app/NovoCliente/novocliente.jsx';
import NovaNoticia from './app/notica/novanoticia.js';
import Arquivos from './app/Downloads/arquivos.jsx';
import EditarUsuario from './app/Editarusuario/editarusuario.jsx';
import EditarCliente from './app/Editarcliente/editarcliente';
import EditarNoticia from './app/Editarnoticia/editarnoticia.jsx';
import { AuthContext } from './app/Context/contextAuth.jsx';


function App() {
  const { logado } = useContext(AuthContext);
  const { admin } = useContext(AuthContext);
  
  function SecureRouteAdm({ ...params }) {
    if (!admin) {
      return <Redirect to="/app" />
    } else {
      return <Route {...params} />
    }
  }
  function SecureRoute({ ...params }) {
    if (!logado) {
      return <Redirect to="/app" />
    } else {
      return <Route {...params} />
    }
  }

  return <BrowserRouter>
    <Switch>
      <Route exact path='/' component={Site} />
      <Route exact path='/app' component={Login} />
      <SecureRouteAdm exact path='/app/home' component={Home} />
      <SecureRoute exact path='/app/homeb' component={Homeb} />
      <SecureRouteAdm exact path='/app/novaconta' component={NovaConta} />
      <SecureRouteAdm exact path='/app/usuarioslist' component={UserTable} />
      <SecureRouteAdm exact path='/app/noticiaslist' component={NoticiaTable} />
      <SecureRouteAdm exact path='/app/novocliente' component={Novocliente} />
      <SecureRouteAdm exact path='/app/novanoticia' component={NovaNoticia} />

      <SecureRoute exact path='/app/arquivos' component={Arquivos} />
      <SecureRouteAdm exact path='/app/editarusuario/:id' component={EditarUsuario} />
      <SecureRouteAdm exact path='/app/editarusuario/:id' component={EditarUsuario} />
      <SecureRouteAdm exact path='/app/editarcliente/:id' component={EditarCliente} />
      <SecureRouteAdm exact path='/app/editarnoticia/:id' component={EditarNoticia} />
    </Switch>
  </BrowserRouter>;
}

export default App;
import React from 'react';

function Menu() {
  return <nav className="navbar fixed-top navbar-expand-md navbar-dark">

    <div className="container">

      <a className="navbar-brand" href="/#">
        <img  src="Images/logo.png" alt="logo" height="48" />
      </a>

      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ms-auto">
          <li className="nav-item">
            <p className="navbar-brand" > Telefone (62)3954-5085</p>
          </li>
          <li className="nav-item">
            <a className="nav-link" aria-current="page" href="#bannerrotativo">Inicio</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" aria-current="page" href="#features">Recursos</a>
          </li>

          <li className="nav-item">
            <a className="nav-link" aria-current="page" href="#download">Downloads</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" aria-current="page" href="#footer">Contato</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" aria-current="page" href="/app">Login</a>
          </li>
        </ul>
      </div>

    </div>
  </nav>;
}
export default Menu;